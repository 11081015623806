import { API_HOST, authenticatedFetchData, authenticatedFetchRaw } from '../lib'

export async function uploadFile(file: File | Blob, charityId: string, documentType: CharityDocument) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charityId}/links/${documentType}`)
    .run()
    .then(({ url }) =>
      fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type
        },
        body: file
      })
    )
}

export function fetchCharities(): {
  run: () => Promise<CharitySummaryWithChangesIndicator[]>
  abort: () => void
} {
  const controller = new AbortController()
  const pending = authenticatedFetchData(`${API_HOST}/charities/v1/pending-charities`, { signal: controller.signal })
  const charities = authenticatedFetchData(`${API_HOST}/charities/v1/charity`, { signal: controller.signal })

  return {
    run: () => {
      return Promise.allSettled([charities.run(), pending.run()])
        .then(([charities, pendingChanges]: any) => ({
          charities: charities.value,
          pendingChanges: pendingChanges.value
        }))
        .then(({ charities, pendingChanges }) => {
          return charities?.reduce((acc: CharitySummaryWithChangesIndicator[], value: CharitySummary) => {
            const hasChanges = pendingChanges.some((ch: CharitySummary) => ch.id === value.id)
            return [...acc, { ...value, hasPendingChanges: hasChanges }]
          }, [])
        })
    },
    abort: () => controller.abort
  }
}

export async function fetchCharity(charityId: string): Promise<Charity> {
  const response = await authenticatedFetchRaw(`${API_HOST}/charities/v1/charity/${charityId}`).run()

  const {
    data,
    meta: { attachments }
  } = await response.json()

  return { ...data, attachments }
}

export async function fetchPendingChangesForCharity(charityId: string) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/pending-charities/${charityId}`)
    .run()
    .catch(error => {
      if (error.status === 404) {
        return {}
      }

      throw error
    })
}

export function fetchOrgNameByAbn(abn: string) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/abn/${abn}/name`).run()
}

export function submitCharity(charity: Charity) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity`, {
    method: 'POST',
    body: JSON.stringify({
      ...charity,
      secondaryContact: charity.secondaryContact?.firstName ? charity.secondaryContact : null
    })
  }).run()
}

export function updateCharity(charity: Charity) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/charity/${charity.id}`, {
    method: 'PATCH',
    body: JSON.stringify(charity)
  }).run()
}

export function getDonationPartnerDetails(donationPartnerId: string) {
  return authenticatedFetchData(`${API_HOST}/charities/v1/donation-partner/${donationPartnerId}`, {
    method: 'GET'
  }).run()
}

export async function fetchExistingCharity(): Promise<Charity | null> {
  const charities = await authenticatedFetchData(`${API_HOST}/charities/v1/charity`).run()

  if (charities.length > 0) {
    const [charity] = charities
    return fetchCharity(charity.id)
  }

  return null
}
