import { FIREBASE_CONFIG } from './lib'
import { initializeApp } from 'firebase/app'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getAuth, connectAuthEmulator, initializeAuth, browserLocalPersistence } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

const isE2E = window.Cypress

const app = initializeApp(FIREBASE_CONFIG)
const storage = getStorage(app)
const auth = isE2E ? initializeAuth(app, { persistence: browserLocalPersistence }) : getAuth(app)
const db = getFirestore(app)

if (isE2E) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  connectStorageEmulator(storage, '127.0.0.1', 9199)
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}

export { app, storage, auth, db }
