import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { isSignInWithEmailLink, onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from './firebase'
import { logError } from '@tomra/datadog-browser-logging'
import { SmallScreenNavBar } from './components/layout/SmallScrenNavBar'
import { LargeScreenNavBar } from './components/layout/LargeScreenNavBar'
import { setCSSViewHeightVariable } from '@tomra/consumer-customer-design-system/config/layout-utils'
import { CharityContextProvider } from './context/CharityContextProvider'
import { CampaignContextProvider } from './context/CampaignContextProvider'

export const AppLayout = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<'toBeDecided' | 'yes' | 'no'>('toBeDecided')
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user === null) {
        setIsAuthenticated('no')

        if (!isSignInWithEmailLink(auth, window.location.href)) {
          return navigate('/login')
        }
      } else {
        window.DD_RUM && window.DD_RUM.setUser({ id: auth.currentUser?.uid })
        setIsAuthenticated('yes')
      }
    })
  }, [navigate])

  useEffect(() => {
    setCSSViewHeightVariable()
  }, [location])

  const logout = async () => {
    try {
      await signOut(auth)
    } catch (error: unknown) {
      logError(new Error('Unable to sign out user'), error as Error)
    }
  }

  return (
    <>
      <SmallScreenNavBar isAuthenticated={isAuthenticated} logout={logout} />

      <LargeScreenNavBar isAuthenticated={isAuthenticated} logout={logout} />

      <div id="content">
        {isAuthenticated === 'toBeDecided' ? (
          <div className="h-full flex items-center justify-center">
            <div className="loading-infinity w-36" />
          </div>
        ) : isAuthenticated === 'no' ? (
          <Outlet />
        ) : (
          <CharityContextProvider>
            <CampaignContextProvider>
              <Outlet />
            </CampaignContextProvider>
          </CharityContextProvider>
        )}
      </div>
    </>
  )
}
