import webStorage from 'localforage'
import { ENVIRONMENT } from '../lib'
import { logError } from '@tomra/datadog-browser-logging'

function clearWebStorage() {
  return webStorage.clear().catch(error => {
    logError(new Error(`Failed to clear web storage`), error)
  })
}

export function getItemFromWebStorage(id: string): Promise<any> {
  return webStorage.getItem(`${ENVIRONMENT}/${id}`).catch(error => {
    logError(new Error(`Failed to get ${id} from web storage`), error)
  })
}

export function setItemInWebStorage(id: string, value: any) {
  return webStorage.setItem(`${ENVIRONMENT}/${id}`, value).catch(error => {
    /*
      Shouldn't really ever be needed, since the amount of data we store is very little
      But we still see this happening, so this is an
      attempt to remedy the issue by completely clearing storage
      so that future saving attempts hopefully will succeed
    */
    if (error && error.toString() === 'QuotaExceededError') {
      clearWebStorage()
    }

    logError(new Error(`Failed to set ${id} in web storage`), error)
  })
}

export function removeItemFromWebStorage(id: string) {
  return webStorage.removeItem(`${ENVIRONMENT}/${id}`).catch(error => {
    logError(new Error(`Failed to remove ${id} from web storage`), error)
  })
}
